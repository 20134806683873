import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'

const styles = {
  root: {
    backgroundColor: '#FAFAFA',
    paddingTop: '53px',
  },
}

const Main = ({children, classes, className, ...other}) => (
  <main
    id="content"
    className={clsx(classes.root, className)}
    itemProp="mainContentOfPage"
    itemScope
    role="main"
    {...other}>
    {children}
  </main>
)

export default withStyles(styles)(Main)
