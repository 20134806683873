import React from 'react'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'

import Link from '@material-ui/core/Link'
import Tab from '@material-ui/core/Tab'

const styles = theme => ({
  root: {
    color: '#333333',
    'font-size': '12px',
    'font-weight': 'normal',
    'letter-spacing': '0.38px',
    'line-height': 1.17,
    'max-width': '205px',
    'min-width': '68px',
    padding: '12px 16px',
    marginLeft: '8px',
    '&:hover': {
      'background-color': '#eeeeee',
      'font-weight': 'normal',
      'text-decoration': 'none',
    },
  },
})

const NavItem = ({classes, className, href, key, label, onClick, ...props}) => {
  const active = props.index === props.active ? classes.active : ''
  return (
    <Tab
      active={active}
      className={clsx(classes.root, className)}
      component={Link}
      data-gtm-identifier="button_text"
      href={href}
      itemProp="url"
      key={key}
      label={label}
      onClick={onClick}
      title={label}
      {...props}
    />
  )
}

export default withStyles(styles)(NavItem)
