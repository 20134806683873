import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'

import Link from '@material-ui/core/Link'

export const props = isExternal => ({
  rel: isExternal ? 'noopener noreferrer' : '',
  target: isExternal ? '_blank' : '_self',
})

const styles = {
  root: {
    display: 'inline-block',
  },
}

const HomeLink = ({children, classes, className, href, isExternal, ...otherProps}) => {
  return (
    <Link
      className={clsx(classes.root, className)}
      href={href}
      title="Ir para o início"
      {...props(isExternal)}
      {...otherProps}>
      {children}
    </Link>
  )
}

HomeLink.defaultProps = {
  href: '#',
  isExternal: false,
}

HomeLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
}

export default withStyles(styles)(HomeLink)
