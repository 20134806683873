import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'

const styles = theme => ({
  root: {
    minHeight: '11px',
    minWidth: '19px',
  },
})

const MenuButton = ({classes, className, onClick, ...props}) => {
  return (
    <IconButton
      id="menu-icon-button"
      aria-label="Menu"
      edge="start"
      onClick={onClick}
      type="button"
      {...props}>
      <MenuIcon className={clsx(classes.root, className)} />
    </IconButton>
  )
}

export default withStyles(styles)(MenuButton)
