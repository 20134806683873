import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import AppBar from '@material-ui/core/AppBar'
import HorizontalMenu from './HorizontalMenu'
import MobileMenu from './MobileMenu'
import clsx from 'clsx'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  menuList: {
    display: 'flex',
    margin: 0,
  },
})

const Header = ({classes, className, ...menuProps}) => (
  <AppBar
    id="header"
    className={clsx(classes.root, className)}
    color="inherit"
    data-gtm-category="landing_header"
    data-gtm-label="bloco_header"
    data-gtm-main-action="view"
    data-gtm-category-ga4="landing_header"
    data-gtm-label-ga4="block_header"
    data-gtm-main-action-ga4="view"
    data-testid="app-bar"
    direction="row"
    elevation={0}
    itemScope={true}
    itemType="http://schema.org/WPHeader"
    position="fixed">
    <MobileMenu {...menuProps} />
    <HorizontalMenu {...menuProps} />
  </AppBar>
)

export default withStyles(styles)(Header)
