import React from 'react'
import CommonButton from './CommonButton'
import Link from '@material-ui/core/Link'

const RegisterCTA = ({children, label, ...props}) => (
  <CommonButton
    aria-label={label}
    component={Link}
    data-gtm-identifier="button_text"
    href="https://accounts.natura-avon.com/auto?country=brazil&company=natura"
    rel="noopener noreferrer"
    target="_blank"
    {...props}>
    {children || label}
  </CommonButton>
)

export default RegisterCTA
