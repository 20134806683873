import React from 'react'
import Image from 'gatsby-image'
import {graphql, StaticQuery} from 'gatsby'

const logoImageQuery = graphql`
  query {
    logoImage: file(relativePath: {eq: "logo.png"}) {
      ...logoImageSize
    }
  }
`
const logoImage = data => (
  <Image alt="Logotipo da Natura" fixed={data.logoImage.childImageSharp.fixed} />
)

const LogoStaticQuery = ({render}) => <StaticQuery render={render} query={logoImageQuery} />
const Logo = () => <LogoStaticQuery render={logoImage} />

export default Logo

export const logoImageSize = graphql`
  fragment logoImageSize on File {
    childImageSharp {
      fixed(width: 62, height: 53, quality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`
