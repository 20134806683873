export const styles = ({palette}) => {
  return {
    copyright: {
      paddingTop: '32px',
      display: 'block',
    },
    footer: {
      paddingTop: '113px',
    },
    footerMobile: {
      paddingTop: '48px',
    },
    footerGrid: {
      backgroundColor: palette.grey.A400,
      minHeight: '244px',
      paddingTop: '32px',
    },
    footerGridMobile: {
      padding: '32px 0 48px 0',
    },
    headsetIcon: {paddingRight: '8px'},
    title: {
      color: palette.text.hint,
      display: 'flex',
    },
    titleMobile: {
      display: 'block',
      textAlign: 'center',
    },
    links: {
      color: palette.common.white,
    },
    listMobile: {
      '& li ': {
        display: 'block',
        textAlign: 'center',
      },
    },
    listDesktopPhoneList: {
      '& li ': {
        paddingLeft: '32px',
      },
    },
    socialIconsContainer: {paddingTop: '13px'},
  }
}
