import FormLabel from '@material-ui/core/FormLabel'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'

const styles = theme => ({
  root: {
    color: theme.palette.grey[700],
    cursor: 'pointer',
    fontSize: '14px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '1.23px',
    lineHeight: '14px',
    minHeight: '14px',
    minWidth: '47px',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
})

const MenuButtonLabel = ({children, classes, className}) => (
  <FormLabel className={clsx(classes.root, className)} htmlFor="menu-icon-button">
    {children}
  </FormLabel>
)

export default withStyles(styles)(MenuButtonLabel)
