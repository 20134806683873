import React, {useEffect} from 'react'

import Drawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@naturacosmeticos/natds-web/dist/Components/Typography'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'

import MenuItems from '../../constants/menuOptions'
import RegisterCTA from '../RegisterCTA'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  divider: {margin: '15px 0'},
  list: {
    backgroundColor: theme.palette.common.white,
    maxWidth: '320px',
    width: '85%',
  },
  listItem: {paddingLeft: '24px', paddingRight: '24px'},
  listItemLink: {outline: 0, width: '100%'},
  listItemText: {fontSize: '0.875rem'},
  slogan: {
    color: '#333333',
    fontSize: '20px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.24px',
    lineHeight: 1.2,
    margin: '56px 40px 16px 0',
    maxWidth: '240px',
    minHeight: '72px',
    textAlign: 'left',
  },
  registerButton: {
    minWidth: '100%',
  },
})

export const NavigationDrawer = ({classes, opened, onClose, ...props}) => {
  return (
    <Drawer
      data-testid="drawer-menu"
      ModalProps={{disableScrollLock: true}}
      classes={{paper: classes.list}}
      component="nav"
      open={opened}
      onClose={onClose}
      {...props}>
      <ListItem classes={{gutters: classes.listItem}}>
        <Typography classes={{root: classes.slogan}} variant="h6">
          Com a Consultoria<br />
          de Beleza Natura #VocêPodeMais
        </Typography>
      </ListItem>
      <Divider classes={{root: classes.divider}} />
      <nav itemScope itemType="http://schema.org/SiteNavigationElement">
        <List component="ul">
          {MenuItems.map(item => (
            <ListItem
              data-testid="drawer-menu-item"
              id={`${item.id}-drawer-menu-item`}
              classes={{gutters: classes.listItem}}
              component="li"
              key={item.href}
              itemProp="name">
              <Link
                classes={{root: classes.listItemLink}}
                color="inherit"
                data-gtm-category="landing_header"
                data-gtm-main-action="click"
                data-gtm-label={item.gtmLabel}
                data-gtm-category-ga4="landing_header"
                data-gtm-main-action-ga4="click"
                data-gtm-label-ga4={item.ga4Label}
                data-gtm-identifier="button_text"
                href={item.href}
                itemProp="url"
                onClick={() => {
                  onClose()
                }}>
                <ListItemText classes={{primary: classes.listItemText}} primary={item.label} />
              </Link>
            </ListItem>
          ))}
        </List>
      </nav>
      <Divider classes={{root: classes.divider}} />
      <List>
        <ListItem classes={{gutters: classes.listItem}}>
          <ListItemText>
            <Link href="tel:03007628872" color="inherit" classes={{root: classes.listItemText}}>
              0300 762 8872
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem
          data-testid="drawer-menu-cta"
          id={`subscribe-drawer-menu-item`}
          classes={{gutters: classes.listItem}}
          component="li"
          key="https://accounts.natura.com/auto?country=brazil"
          itemProp="name">
          <RegisterCTA label="QUERO ME CADASTRAR" className={classes.registerButton} />
        </ListItem>
      </List>
    </Drawer>
  )
}

export default withStyles(styles)(NavigationDrawer)
