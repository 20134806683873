let menuOptions = [
  {
    id: 'home',
    href: '#',
    label: 'Home',
    gtmLabel: 'menu_home',
    ga4Label: 'Início',
  },
  {
    id: 'benefits',
    href: '#vantagens',
    label: 'Vantagens',
    gtmLabel: 'menu_beneficio',
    ga4Label: 'Vantagens',
  },
  {
    id: 'earnings',
    href: '#ganhos-como-consultora',
    label: 'Ganhos como consultora',
    gtmLabel: 'menu_simule_seus_lucros',
    ga4Label: 'Lucros e Ganhos',
  },
  {
    id: 'our-causes',
    href: '#nossas-causas',
    label: 'Nossas Causas',
    gtmLabel: 'menu_nossas_causas',
    ga4Label: 'Nossos Diferenciais',
  },
  {
    id: 'help',
    href: '#ajuda',
    label: 'Ajuda',
    gtmLabel: 'menu_ajuda',
    ga4Label: 'Ajuda',
  },
]

export default menuOptions
