import React from 'react'
import {Provider, themes} from '@naturacosmeticos/natds-web'
import Header from '../components/Header/Header'
import Main from '../components/Main'
import Link from '@material-ui/core/Link'
import Typography from '@naturacosmeticos/natds-web/dist/Components/Typography'
import Logo from '../components/Logo'
import Footer from '../components/Footer'

const DefaultLayout = ({children}) => {
  return (
    <Provider theme={themes.natura.light}>
      <Typography variant="srOnly">
        <Link href="#content">Pular para o conteúdo</Link>
      </Typography>
      <Header homeUrl="#" logoImage={<Logo />} />
      <Main>{children}</Main>
      <Footer />
    </Provider>
  )
}

export default DefaultLayout
