import React, {useState} from 'react'
import Hidden from '@material-ui/core/Hidden'
import GridContainer from '@naturacosmeticos/natds-web/dist/Components/GridContainer'
import Container from '@material-ui/core/Container'
import GridItem from '@naturacosmeticos/natds-web/dist/Components/GridItem'
import HomeLink from '../HomeLink'
import MenuItems from '../../constants/menuOptions'
import NavItem from '../NavItem'
import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import Tabs from '@material-ui/core/Tabs'

const styles = theme => ({
  root: {
    height: '40px',
  },
  menuList: {
    display: 'flex',
    margin: 0,
  },
})

const HorizontalMenu = ({classes, className, homeUrl, logoImage}) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <Hidden implementation="css" smDown={true}>
      <GridContainer
        alignItems="center"
        container
        component={Container}
        direction="row"
        itemScope
        justify="space-between">
        <GridItem>
          <HomeLink
            href={homeUrl}
            isExternal={false}
            data-gtm-category-ga4="landing_header"
            data-gtm-label-ga4="logo"
            data-gtm-main-action-ga4="click">
            {logoImage}
          </HomeLink>
        </GridItem>
        <GridItem className={classes.root}>
          <Tabs
            component="nav"
            indicatorColor="secondary"
            className={clsx(classes.menuList, className)}
            onChange={(e, index) => {
              setActiveTab(index)
            }}
            value={activeTab}>
            {MenuItems.map((item, index) => (
              <NavItem
                id={`${item.id}-horizontal-menu-item`}
                active={activeTab}
                data-gtm-category="landing_header"
                data-gtm-label={item.gtmLabel}
                data-gtm-main-action="click"
                data-gtm-category-ga4="landing_header"
                data-gtm-label-ga4={item.ga4Label}
                data-gtm-main-action-ga4="click"
                data-testid="menu-item"
                href={item.href}
                index={index}
                key={item.label}
                label={item.label}
                onClick={() => {
                  setActiveTab(index)
                }}
              />
            ))}
          </Tabs>
        </GridItem>
      </GridContainer>
    </Hidden>
  )
}

export default withStyles(styles)(HorizontalMenu)
